@if (type === 'link' && link.url.startsWith('#')) {
	<a
		[class]="className + 'text-link'"
		[title]="link.title"
		(click)="setHash(link.url)"
		rel="noopener noreferrer"
		target="_self"
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'button' && link.url.startsWith('#')) {
	<a
		[class]="buttonClassName + ' ' + className"
		[title]="link.title"
		(click)="setHash(link.url)"
		rel="noopener noreferrer"
		target="_self"
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'link' && link.url.startsWith('http')) {
	<a
		[class]="className + ' text-link'"
		[href]="link.url"
		[target]="link.target || '_blank'"
		[title]="link.title"
		(click)="linkClick.emit()"
		rel="noopener noreferrer"
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'link' && !link.url.startsWith('http')) {
	<a
		[class]="className + ' text-link'"
		[routerLink]="link.url"
		[target]="link.target || '_self'"
		[title]="link.title"
		(click)="linkClick.emit()"
		valkCoreLink
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'link-unstyled' && link.url.startsWith('http')) {
	<a
		[class]="className"
		[href]="link.url"
		[target]="link.target || '_blank'"
		[title]="link.title"
		(click)="linkClick.emit()"
		rel="noopener noreferrer"
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'link-unstyled' && !link.url.startsWith('http')) {
	<a
		[class]="className"
		[routerLink]="link.url"
		[target]="link.target || '_self'"
		[title]="link.title"
		(click)="linkClick.emit()"
		valkCoreLink
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'button' && link.url.startsWith('http')) {
	<a
		[class]="buttonClassName + ' ' + className"
		[href]="link.url"
		[target]="link.target || '_blank'"
		[title]="link.title"
		(click)="linkClick.emit()"
		rel="noopener noreferrer"
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
} @else if (type === 'button' && !link.url.startsWith('http')) {
	<a
		[class]="buttonClassName + ' ' + className"
		[routerLink]="link.url"
		[target]="link.target || '_self'"
		[title]="link.title"
		(click)="linkClick.emit()"
		valkCoreLink
	>
		<ng-container *ngTemplateOutlet="linkContent" />
	</a>
}

<ng-template #linkContent>
	<ng-content />
</ng-template>
