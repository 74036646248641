<header
	[class]="textAlignment"
	[editableInStoryblok]="_editable"
	class="font-display"
>
	@switch (headingType) {
		@case ('h1') {
			<h1 [ngClass]="headingSize">
				<markdown [data]="title" />
			</h1>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ overline }}
			</p>
		}
		@case ('h2') {
			<h2 [ngClass]="headingSize">
				<markdown [data]="title" />
			</h2>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ overline }}
			</p>
		}
		@case ('h3') {
			<h3 [ngClass]="headingSize">
				<markdown [data]="title" />
			</h3>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ overline }}
			</p>
		}
		@case ('h4') {
			<h4 [ngClass]="headingSize">
				<markdown [data]="title" />
			</h4>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ overline }}
			</p>
		}
		@case ('h5') {
			<h5 [ngClass]="headingSize">
				<markdown [data]="title" />
				<p class="heading-group-overline">{{ overline }}</p>
			</h5>
		}
		@case ('h6') {
			<h6 [ngClass]="headingSize">
				<markdown [data]="title" />
			</h6>
			<p class="heading-group-overline" role="doc-subtitle">
				{{ overline }}
			</p>
		}
	}
</header>
