let N = !1;
const M = [],
  z = i => new Promise((e, t) => {
    if (typeof window > "u" || (window.storyblokRegisterEvent = r => {
      if (window.location === window.parent.location) {
        console.warn("You are not in Draft Mode or in the Visual Editor.");
        return;
      }
      N ? r() : M.push(r);
    }, document.getElementById("storyblok-javascript-bridge"))) return;
    const s = document.createElement("script");
    s.async = !0, s.src = i, s.id = "storyblok-javascript-bridge", s.onerror = r => t(r), s.onload = r => {
      M.forEach(n => n()), N = !0, e(r);
    }, document.getElementsByTagName("head")[0].appendChild(s);
  });
var F = Object.defineProperty,
  K = (i, e, t) => e in i ? F(i, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : i[e] = t,
  p = (i, e, t) => K(i, typeof e != "symbol" ? e + "" : e, t);
function H(i) {
  return !(i !== i || i === 1 / 0 || i === -1 / 0);
}
function Y(i, e, t) {
  if (!H(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!H(t)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    n = 0;
  const o = function () {
      n++;
      const a = setTimeout(function () {
        n--, s.length > 0 && o(), r = r.filter(function (d) {
          return d !== a;
        });
      }, t);
      r.indexOf(a) < 0 && r.push(a);
      const h = s.shift();
      h.resolve(i.apply(h.self, h.args));
    },
    l = function (...a) {
      const h = this;
      return new Promise(function (d, u) {
        s.push({
          resolve: d,
          reject: u,
          args: a,
          self: h
        }), n < e && o();
      });
    };
  return l.abort = function () {
    r.forEach(clearTimeout), r = [], s.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, l;
}
class x {
  constructor() {
    p(this, "isCDNUrl", (e = "") => e.indexOf("/cdn/") > -1), p(this, "getOptionsPage", (e, t = 25, s = 1) => ({
      ...e,
      per_page: t,
      page: s
    })), p(this, "delay", e => new Promise(t => setTimeout(t, e))), p(this, "arrayFrom", (e = 0, t) => [...Array(e)].map(t)), p(this, "range", (e = 0, t = e) => {
      const s = Math.abs(t - e) || 0,
        r = e < t ? 1 : -1;
      return this.arrayFrom(s, (n, o) => o * r + e);
    }), p(this, "asyncMap", async (e, t) => Promise.all(e.map(t))), p(this, "flatMap", (e = [], t) => e.map(t).reduce((s, r) => [...s, ...r], [])), p(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        s = /[&<>"']/g,
        r = RegExp(s.source);
      return e && r.test(e) ? e.replace(s, n => t[n]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(e, t, s) {
    const r = [];
    for (const n in e) {
      if (!Object.prototype.hasOwnProperty.call(e, n)) continue;
      const o = e[n],
        l = s ? "" : encodeURIComponent(n);
      let a;
      typeof o == "object" ? a = this.stringify(o, t ? t + encodeURIComponent("[" + l + "]") : l, Array.isArray(o)) : a = (t ? t + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(o), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn",
      n = "api-ap.storyblok.com",
      o = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return n;
      case "ca":
        return o;
      default:
        return t;
    }
  }
}
const X = function (i, e) {
    const t = {};
    for (const s in i) {
      const r = i[s];
      e.indexOf(s) > -1 && r !== null && (t[s] = r);
    }
    return t;
  },
  W = i => i === "email",
  Q = () => ({
    singleTag: "hr"
  }),
  Z = () => ({
    tag: "blockquote"
  }),
  ee = () => ({
    tag: "ul"
  }),
  te = i => ({
    tag: ["pre", {
      tag: "code",
      attrs: i.attrs
    }]
  }),
  se = () => ({
    singleTag: "br"
  }),
  re = i => ({
    tag: `h${i.attrs.level}`
  }),
  ie = i => ({
    singleTag: [{
      tag: "img",
      attrs: X(i.attrs, ["src", "alt", "title"])
    }]
  }),
  ne = () => ({
    tag: "li"
  }),
  oe = () => ({
    tag: "ol"
  }),
  ae = () => ({
    tag: "p"
  }),
  le = i => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": i.attrs.name,
        emoji: i.attrs.emoji
      }
    }]
  }),
  ce = () => ({
    tag: "b"
  }),
  he = () => ({
    tag: "s"
  }),
  ue = () => ({
    tag: "u"
  }),
  de = () => ({
    tag: "strong"
  }),
  pe = () => ({
    tag: "code"
  }),
  ge = () => ({
    tag: "i"
  }),
  fe = i => {
    if (!i.attrs) return {
      tag: ""
    };
    const e = new x().escapeHTML,
      t = {
        ...i.attrs
      },
      {
        linktype: s = "url"
      } = i.attrs;
    if (delete t.linktype, t.href && (t.href = e(i.attrs.href || "")), W(s) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const r in t.custom) t[r] = t.custom[r];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  me = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  ye = () => ({
    tag: "sub"
  }),
  be = () => ({
    tag: "sup"
  }),
  ke = i => ({
    tag: [{
      tag: "span",
      attrs: i.attrs
    }]
  }),
  ve = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${i.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  $e = i => {
    var e;
    return (e = i.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${i.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  Te = {
    nodes: {
      horizontal_rule: Q,
      blockquote: Z,
      bullet_list: ee,
      code_block: te,
      hard_break: se,
      heading: re,
      image: ie,
      list_item: ne,
      ordered_list: oe,
      paragraph: ae,
      emoji: le
    },
    marks: {
      bold: ce,
      strike: he,
      underline: ue,
      strong: de,
      code: pe,
      italic: ge,
      link: fe,
      styled: me,
      subscript: ye,
      superscript: be,
      anchor: ke,
      highlight: ve,
      textStyle: $e
    }
  },
  Re = function (i) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      s = RegExp(t.source);
    return i && s.test(i) ? i.replace(t, r => e[r]) : i;
  };
let U = !1;
class we {
  constructor(e) {
    p(this, "marks"), p(this, "nodes"), e || (e = Te), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }, s = !0) {
    if (!U && s && (console.warn("Warning ⚠️: The RichTextResolver class is deprecated and will be removed in the next major release. Please use the `@storyblok/richtext` package instead. https://github.com/storyblok/richtext/"), U = !0), e && e.content && Array.isArray(e.content)) {
      let r = "";
      return e.content.forEach(n => {
        r += this.renderNode(n);
      }), t.optimizeImages ? this.optimizeImages(r, t.optimizeImages) : r;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`), "";
  }
  optimizeImages(e, t) {
    let s = 0,
      r = 0,
      n = "",
      o = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (n += `width="${t.width}" `, s = t.width), typeof t.height == "number" && t.height > 0 && (n += `height="${t.height}" `, r = t.height), (t.loading === "lazy" || t.loading === "eager") && (n += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (n += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (o += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (o += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-Fa-f]{6}/g) || t.filters.fill === "transparent") && (o += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (o += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (o += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (o += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (o += `:rotate(${t.filters.rotate})`), o.length > 0 && (o = "/filters" + o))), n.length > 0 && (e = e.replace(/<img/g, `<img ${n.trim()}`));
    const l = s > 0 || r > 0 || o.length > 0 ? `${s}x${r}${o}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${l}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var h, d;
      const u = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (u && u.length > 0) {
        const m = {
          srcset: (h = t.srcset) == null ? void 0 : h.map(y => {
            if (typeof y == "number") return `//${u}/m/${y}x0${o} ${y}w`;
            if (typeof y == "object" && y.length === 2) {
              let w = 0,
                E = 0;
              return typeof y[0] == "number" && (w = y[0]), typeof y[1] == "number" && (E = y[1]), `//${u}/m/${w}x${E}${o} ${w}w`;
            }
          }).join(", "),
          sizes: (d = t.sizes) == null ? void 0 : d.map(y => y).join(", ")
        };
        let k = "";
        return m.srcset && (k += `srcset="${m.srcset}" `), m.sizes && (k += `sizes="${m.sizes}" `), a.replace(/<img/g, `<img ${k.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(r => {
      const n = this.getMatchingMark(r);
      n && n.tag !== "" && t.push(this.renderOpeningTag(n.tag));
    });
    const s = this.getMatchingNode(e);
    return s && s.tag && t.push(this.renderOpeningTag(s.tag)), e.content ? e.content.forEach(r => {
      t.push(this.renderNode(r));
    }) : e.text ? t.push(Re(e.text)) : s && s.singleTag ? t.push(this.renderTag(s.singleTag, " /")) : s && s.html ? t.push(s.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), s && s.tag && t.push(this.renderClosingTag(s.tag)), e.marks && e.marks.slice(0).reverse().forEach(r => {
      const n = this.getMatchingMark(r);
      n && n.tag !== "" && t.push(this.renderClosingTag(n.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let r = `<${s.tag}`;
        if (s.attrs) {
          for (const n in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, n)) {
            const o = s.attrs[n];
            o !== null && (r += ` ${n}="${o}"`);
          }
        }
        return `${r}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(t => t.constructor === String ? `</${t}>` : `</${t.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const j = we;
class Se {
  constructor(e) {
    p(this, "baseURL"), p(this, "timeout"), p(this, "headers"), p(this, "responseInterceptor"), p(this, "fetch"), p(this, "ejectInterceptor"), p(this, "url"), p(this, "parameters"), p(this, "fetchOptions"), this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(r => {
      s.data = r;
    }));
    for (const r of e.headers.entries()) t[r[0]] = r[1];
    return s.headers = {
      ...t
    }, s.status = e.status, s.statusText = e.statusText, s;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      s = null;
    if (e === "get") {
      const a = new x();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(t),
      n = new AbortController(),
      {
        signal: o
      } = n;
    let l;
    this.timeout && (l = setTimeout(() => n.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: e,
        headers: this.headers,
        body: s,
        signal: o,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const h = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(h)) : this._statusHandler(h);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((s, r) => {
      if (t.test(`${e.status}`)) return s(e);
      const n = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      r(n);
    });
  }
}
const _e = Se,
  D = "SB-Agent",
  L = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let I = {};
const S = {};
class Ee {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(e, t) {
    p(this, "client"), p(this, "maxRetries"), p(this, "retriesDelay"), p(this, "throttle"), p(this, "accessToken"), p(this, "cache"), p(this, "helpers"), p(this, "resolveCounter"), p(this, "relations"), p(this, "links"), p(this, "richTextResolver"), p(this, "resolveNestedRelations"), p(this, "stringifiedStoriesCache");
    let s = e.endpoint || t;
    if (!s) {
      const o = new x().getRegionURL,
        l = e.https === !1 ? "http" : "https";
      e.oauthToken ? s = `${l}://${o(e.region)}/v1` : s = `${l}://${o(e.region)}/v2`;
    }
    const r = new Headers();
    r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), e.headers && (e.headers.constructor.name === "Headers" ? e.headers.entries().toArray() : Object.entries(e.headers)).forEach(([o, l]) => {
      r.set(o, l);
    }), r.has(D) || (r.set(D, L.defaultAgentName), r.set(L.defaultAgentVersion, L.packageVersion));
    let n = 5;
    e.oauthToken && (r.set("Authorization", e.oauthToken), n = 3), e.rateLimit && (n = e.rateLimit), e.richTextSchema ? this.richTextResolver = new j(e.richTextSchema) : this.richTextResolver = new j(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = Y(this.throttledRequest, n, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new x(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new _e({
      baseURL: s,
      timeout: e.timeout || 0,
      headers: r,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let s = "";
      return t.attrs.body && t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = S[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, s, r, n) {
    const o = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, s, r));
    return this.cacheResponse(e, o, void 0, n);
  }
  get(e, t, s) {
    t || (t = {});
    const r = `/${e}`,
      n = this.factoryParamOptions(r, t);
    return this.cacheResponse(r, n, void 0, s);
  }
  async getAll(e, t, s, r) {
    const n = (t == null ? void 0 : t.per_page) || 25,
      o = `/${e}`,
      l = o.split("/"),
      a = s || l[l.length - 1],
      h = 1,
      d = await this.makeRequest(o, t, n, h, r),
      u = d.total ? Math.ceil(d.total / n) : 1,
      m = await this.helpers.asyncMap(this.helpers.range(h, u), k => this.makeRequest(o, t, n, k + 1, r));
    return this.helpers.flatMap([d, ...m], k => Object.values(k.data[a]));
  }
  post(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("post", r, t, s));
  }
  put(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("put", r, t, s));
  }
  delete(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("delete", r, t, s));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, s) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, s);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, s) {
    const r = e[t];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? (this.stringifiedStoriesCache[t] || (this.stringifiedStoriesCache[t] = JSON.stringify(this.relations[e][t])), JSON.parse(this.stringifiedStoriesCache[t])) : t;
  }
  _insertRelations(e, t, s, r) {
    s.indexOf(`${e.component}.${t}`) > -1 && (typeof e[t] == "string" ? e[t] = this.getStoryReference(r, e[t]) : Array.isArray(e[t]) && (e[t] = e[t].map(n => this.getStoryReference(r, n)).filter(Boolean)));
  }
  iterateTree(e, t, s) {
    const r = n => {
      if (n != null) {
        if (n.constructor === Array) for (let o = 0; o < n.length; o++) r(n[o]);else if (n.constructor === Object) {
          if (n._stopResolving) return;
          for (const o in n) (n.component && n._uid || n.type === "link") && (this._insertRelations(n, o, t, s), this._insertLinks(n, o, s)), r(n[o]);
        }
      }
    };
    r(e.content);
  }
  async resolveLinks(e, t, s) {
    let r = [];
    if (e.link_uuids) {
      const n = e.link_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < n; a += l) {
        const h = Math.min(n, a + l);
        o.push(e.link_uuids.slice(a, h));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(",")
      })).data.stories.forEach(h => {
        r.push(h);
      });
    } else r = e.links;
    r.forEach(n => {
      this.links[s][n.uuid] = {
        ...n,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, s) {
    let r = [];
    if (e.rel_uuids) {
      const n = e.rel_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < n; a += l) {
        const h = Math.min(n, a + l);
        o.push(e.rel_uuids.slice(a, h));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(h => {
        r.push(h);
      });
    } else r = e.rels;
    r && r.length > 0 && r.forEach(n => {
      this.relations[s][n.uuid] = {
        ...n,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, s) {
    var r, n;
    let o = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (o = t.resolve_relations.split(",")), await this.resolveRelations(e, t, s)), t.resolve_links && ["1", "story", "url", "link"].indexOf(t.resolve_links) > -1 && ((r = e.links) != null && r.length || (n = e.link_uuids) != null && n.length) && (await this.resolveLinks(e, t, s)), this.resolveNestedRelations) for (const l in this.relations[s]) this.iterateTree(this.relations[s][l], o, s);
    e.story ? this.iterateTree(e.story, o, s) : e.stories.forEach(l => {
      this.iterateTree(l, o, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(e, t, s, r) {
    const n = this.helpers.stringify({
        url: e,
        params: t
      }),
      o = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e != "/cdn/spaces/me") {
      const l = await o.get(n);
      if (l) return Promise.resolve(l);
    }
    return new Promise(async (l, a) => {
      var h;
      try {
        const d = await this.throttle("get", e, t, r);
        if (d.status !== 200) return a(d);
        let u = {
          data: d.data,
          headers: d.headers
        };
        if ((h = d.headers) != null && h["per-page"] && (u = Object.assign({}, u, {
          perPage: d.headers["per-page"] ? parseInt(d.headers["per-page"]) : 0,
          total: d.headers["per-page"] ? parseInt(d.headers.total) : 0
        })), u.data.story || u.data.stories) {
          const m = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(u.data, t, `${m}`);
        }
        return t.version === "published" && e != "/cdn/spaces/me" && (await o.set(n, u)), u.data.cv && t.token && (t.version === "draft" && S[t.token] != u.data.cv && (await this.flushCache()), S[t.token] = t.cv ? t.cv : u.data.cv), l(u);
      } catch (d) {
        if (d.response && d.status === 429 && (s = typeof s > "u" ? 0 : s + 1, s < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, s).then(l).catch(a);
        a(d);
      }
    });
  }
  throttledRequest(e, t, s, r) {
    return this.client.setFetchOptions(r), this.client[e](t, s);
  }
  cacheVersions() {
    return S;
  }
  cacheVersion() {
    return S[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (S[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (S[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(I[e]);
          },
          getAll() {
            return Promise.resolve(I);
          },
          set(e, t) {
            return I[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return I = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
const Ce = (i = {}) => {
    const {
      apiOptions: e
    } = i;
    if (!e.accessToken) {
      console.error("You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication");
      return;
    }
    return {
      storyblokApi: new Ee(e)
    };
  },
  Pe = i => {
    if (typeof i != "object" || typeof i._editable > "u") return {};
    try {
      const e = JSON.parse(i._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, ""));
      return e ? {
        "data-blok-c": JSON.stringify(e),
        "data-blok-uid": e.id + "-" + e.uid
      } : {};
    } catch {
      return {};
    }
  };
var b = /* @__PURE__ */(i => (i.DOCUMENT = "doc", i.HEADING = "heading", i.PARAGRAPH = "paragraph", i.QUOTE = "blockquote", i.OL_LIST = "ordered_list", i.UL_LIST = "bullet_list", i.LIST_ITEM = "list_item", i.CODE_BLOCK = "code_block", i.HR = "horizontal_rule", i.BR = "hard_break", i.IMAGE = "image", i.EMOJI = "emoji", i.COMPONENT = "blok", i))(b || {}),
  v = /* @__PURE__ */(i => (i.BOLD = "bold", i.STRONG = "strong", i.STRIKE = "strike", i.UNDERLINE = "underline", i.ITALIC = "italic", i.CODE = "code", i.LINK = "link", i.ANCHOR = "anchor", i.STYLED = "styled", i.SUPERSCRIPT = "superscript", i.SUBSCRIPT = "subscript", i.TEXT_STYLE = "textStyle", i.HIGHLIGHT = "highlight", i))(v || {}),
  B = /* @__PURE__ */(i => (i.TEXT = "text", i))(B || {}),
  _ = /* @__PURE__ */(i => (i.URL = "url", i.STORY = "story", i.ASSET = "asset", i.EMAIL = "email", i))(_ || {});
function Ie(i, e) {
  if (!e) return {
    src: i,
    attrs: {}
  };
  let t = 0,
    s = 0;
  const r = {},
    n = [];
  function o(a, h, d, u, m) {
    typeof a != "number" || a <= h || a >= d ? console.warn(`[StoryblokRichText] - ${u.charAt(0).toUpperCase() + u.slice(1)} value must be a number between ${h} and ${d} (inclusive)`) : m.push(`${u}(${a})`);
  }
  if (typeof e == "object") {
    if (typeof e.width == "number" && e.width > 0 ? (r.width = e.width, t = e.width) : console.warn("[StoryblokRichText] - Width value must be a number greater than 0"), e.height && typeof e.height == "number" && e.height > 0 ? (r.height = e.height, s = e.height) : console.warn("[StoryblokRichText] - Height value must be a number greater than 0"), e.loading && ["lazy", "eager"].includes(e.loading) && (r.loading = e.loading), e.class && (r.class = e.class), e.filters) {
      const {
          filters: a
        } = e || {},
        {
          blur: h,
          brightness: d,
          fill: u,
          format: m,
          grayscale: k,
          quality: y,
          rotate: w
        } = a || {};
      h && o(h, 0, 100, "blur", n), y && o(y, 0, 100, "quality", n), d && o(d, 0, 100, "brightness", n), u && n.push(`fill(${u})`), k && n.push("grayscale()"), w && [0, 90, 180, 270].includes(e.filters.rotate || 0) && n.push(`rotate(${w})`), m && ["webp", "png", "jpeg"].includes(m) && n.push(`format(${m})`);
    }
    e.srcset && (r.srcset = e.srcset.map(a => {
      if (typeof a == "number") return `${i}/m/${a}x0/${n.length > 0 ? "filters:" + n.join(":") : ""} ${a}w`;
      if (Array.isArray(a) && a.length === 2) {
        const [h, d] = a;
        return `${i}/m/${h}x${d}/${n.length > 0 ? "filters:" + n.join(":") : ""} ${h}w`;
      }
    }).join(", ")), e.sizes && (r.sizes = e.sizes.join(", "));
  }
  let l = `${i}/m/`;
  return t > 0 && s > 0 && (l = `${l}${t}x${s}/`), n.length > 0 && (l = `${l}filters:${n.join(":")}`), {
    src: l,
    attrs: r
  };
}
const xe = (i = {}) => Object.keys(i).map(e => `${e}="${i[e]}"`).join(" "),
  je = (i = {}) => Object.keys(i).map(e => `${e}: ${i[e]}`).join("; ");
function Oe(i) {
  return i.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
}
function Le(i, e = {}, t) {
  const s = xe(e);
  return `<${s ? `${i} ${s}` : i}>${Array.isArray(t) ? t.join("") : t || ""}</${i}>`;
}
function Ne(i = {}) {
  let e = 0;
  const {
      renderFn: t = Le,
      textFn: s = Oe,
      resolvers: r = {},
      optimizeImages: n = !1
    } = i,
    o = c => g => t(c, {
      ...g.attrs,
      key: `${c}-${e}`
    }, g.children || null),
    l = c => {
      const {
        src: g,
        alt: f,
        ...$
      } = c.attrs || {};
      let T = g,
        R = {};
      if (n) {
        const {
          src: V,
          attrs: J
        } = Ie(g, n);
        T = V, R = J;
      }
      const G = {
        src: T,
        alt: f || "",
        key: `img-${e}`,
        ...$,
        ...R
      };
      return t("img", G, "");
    },
    a = c => {
      const {
        level: g,
        ...f
      } = c.attrs || {};
      return t(`h${g}`, {
        ...f,
        key: `h${g}-${e}`
      }, c.children);
    },
    h = c => {
      var g, f, $, T;
      return t("span", {
        "data-type": "emoji",
        "data-name": (g = c.attrs) == null ? void 0 : g.name,
        emoji: (f = c.attrs) == null ? void 0 : f.emoji,
        key: `emoji-${e}`
      }, t("img", {
        src: ($ = c.attrs) == null ? void 0 : $.fallbackImage,
        alt: (T = c.attrs) == null ? void 0 : T.alt,
        style: "width: 1.25em; height: 1.25em; vertical-align: text-top",
        draggable: "false",
        loading: "lazy"
      }, ""));
    },
    d = c => t("pre", {
      ...c.attrs,
      key: `code-${e}`
    }, t("code", {
      key: `code-${e}`
    }, c.children || "")),
    u = (c, g = !1) => ({
      text: f,
      attrs: $
    }) => t(c, g ? {
      style: je($),
      key: `${c}-${e}`
    } : {
      ...$,
      key: `${c}-${e}`
    }, f),
    m = c => O(c),
    k = c => {
      const {
        marks: g,
        ...f
      } = c;
      return "text" in c ? g ? g.reduce(($, T) => m({
        ...T,
        text: $
      }),
      // Fix: Ensure render function returns a string
      m({
        ...f,
        children: f.children
      })
      // Fix: Cast children to string
      ) : s(f.text) : "";
    },
    y = c => {
      const {
        linktype: g,
        href: f,
        anchor: $,
        ...T
      } = c.attrs || {};
      let R = "";
      switch (g) {
        case _.ASSET:
        case _.URL:
          R = f;
          break;
        case _.EMAIL:
          R = `mailto:${f}`;
          break;
        case _.STORY:
          R = f;
          break;
      }
      return $ && (R = `${R}#${$}`), t("a", {
        ...T,
        href: R,
        key: `a-${e}`
      }, c.text);
    },
    w = c => {
      var g, f;
      return console.warn("[StoryblokRichtText] - BLOK resolver is not available for vanilla usage"), t("span", {
        blok: (g = c == null ? void 0 : c.attrs) == null ? void 0 : g.body[0],
        id: (f = c.attrs) == null ? void 0 : f.id,
        key: `component-${e}`,
        style: "display: none"
      }, "");
    },
    E = new Map([[b.DOCUMENT, o("div")], [b.HEADING, a], [b.PARAGRAPH, o("p")], [b.UL_LIST, o("ul")], [b.OL_LIST, o("ol")], [b.LIST_ITEM, o("li")], [b.IMAGE, l], [b.EMOJI, h], [b.CODE_BLOCK, d], [b.HR, o("hr")], [b.BR, o("br")], [b.QUOTE, o("blockquote")], [b.COMPONENT, w], [B.TEXT, k], [v.LINK, y], [v.ANCHOR, y], [v.STYLED, u("span", !0)], [v.BOLD, u("strong")], [v.TEXT_STYLE, u("span", !0)], [v.ITALIC, u("em")], [v.UNDERLINE, u("u")], [v.STRIKE, u("s")], [v.CODE, u("code")], [v.SUPERSCRIPT, u("sup")], [v.SUBSCRIPT, u("sub")], [v.HIGHLIGHT, u("mark")], ...Object.entries(r).map(([c, g]) => [c, g])]);
  function P(c) {
    e += 1;
    const g = E.get(c.type);
    if (!g) return console.error("<Storyblok>", `No resolver found for node type ${c.type}`), "";
    if (c.type === "text") return g(c);
    const f = c.content ? c.content.map(O) : void 0;
    return g({
      ...c,
      children: f
      // Fix: Update the type of 'children' to Node[]
    });
  }
  function O(c) {
    return Array.isArray(c) ? c.map(P) : P(c);
  }
  return {
    render: O
  };
}
let A,
  C = "https://app.storyblok.com/f/storyblok-v2-latest.js";
const Me = (i, e, t = {}) => {
    var l;
    const r = !(typeof window > "u") && typeof window.storyblokRegisterEvent < "u",
      o = +new URL((l = window.location) == null ? void 0 : l.href).searchParams.get("_storyblok") === i;
    if (!(!r || !o)) {
      if (!i) {
        console.warn("Story ID is not defined. Please provide a valid ID.");
        return;
      }
      window.storyblokRegisterEvent(() => {
        new window.StoryblokBridge(t).on(["input", "published", "change"], h => {
          h.action === "input" && h.story.id === i ? e(h.story) : (h.action === "change" || h.action === "published") && h.storyId === i && window.location.reload();
        });
      });
    }
  },
  He = (i = {}) => {
    var u, m;
    const {
      bridge: e,
      accessToken: t,
      use: s = [],
      apiOptions: r = {},
      richText: n = {},
      bridgeUrl: o
    } = i;
    r.accessToken = r.accessToken || t;
    const l = {
      bridge: e,
      apiOptions: r
    };
    let a = {};
    s.forEach(k => {
      a = {
        ...a,
        ...k(l)
      };
    }), o && (C = o);
    const d = !(typeof window > "u") && ((m = (u = window.location) == null ? void 0 : u.search) == null ? void 0 : m.includes("_storyblok_tk"));
    return e !== !1 && d && z(C), A = new j(n.schema), n.resolver && q(A, n.resolver), a;
  },
  q = (i, e) => {
    i.addNode("blok", t => {
      let s = "";
      return t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  },
  Ae = i => !i || !(i != null && i.content.some(e => e.content || e.type === "blok" || e.type === "horizontal_rule")),
  Ue = (i, e, t) => {
    let s = t || A;
    if (!s) {
      console.error("Please initialize the Storyblok SDK before calling the renderRichText function");
      return;
    }
    return Ae(i) ? "" : (e && (s = new j(e.schema), e.resolver && q(s, e.resolver)), s.render(i, {}, !1));
  },
  De = () => z(C);
export { b as BlockTypes, v as MarkTypes, j as RichTextResolver, Te as RichTextSchema, B as TextTypes, Ce as apiPlugin, Ae as isRichTextEmpty, De as loadStoryblokBridge, Me as registerStoryblokBridge, Ue as renderRichText, Ne as richTextResolver, Pe as storyblokEditable, He as storyblokInit, Me as useStoryblokBridge };