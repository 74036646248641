import { Params, UrlSegment } from '@angular/router';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { ISbStoryData } from '@storyblok/js';

import {
	fallbackLanguage,
	Language,
	negativeHeader$,
} from '@valk-nx/core/lib/core';
import { CheckedOption } from '@valk-nx/core/lib/interfaces/form.interface';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
import { LanguageSwitchHelper } from '@valk-nx/helpers/lib/language/language-switch';
import {
	BynderImage,
	FormCheckboxInterface,
	FormHeaderInterface,
	FormInputInterface,
	FormSelectInterface,
	Link,
	RootInterface,
	SbFooterMenu,
	StoryblokLink,
	TranslatedStoryblokLink,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

export class StoryblokHelper {
	static getStoryblokParameters(
		urlSegments: UrlSegment[],
		queryParams: Params,
	): RootInterface {
		const isDraft = Boolean(queryParams['_storyblok']);
		const isLanguageInPath =
			urlSegments.length &&
			LanguageSwitchHelper.isLanguageCode(urlSegments[0].path);
		const language = (
			isLanguageInPath ? urlSegments[0].path : fallbackLanguage
		) as Language;
		const slug =
			urlSegments
				.splice(isLanguageInPath ? 1 : 0)
				.map((urlSegment) => urlSegment.path)
				.join('/') || 'home';

		return { slug, isDraft, language };
	}

	static mapFooterMenu(menu: SbFooterMenu[] | undefined): {
		title: string;
		items: { title: string; url: string; target: string }[];
	}[] {
		if (!menu) {
			return [];
		}

		return menu.map((menuItem: SbFooterMenu) => {
			return {
				title: menuItem.title,
				items: menuItem.items.map((item) => {
					return {
						title: item.title,
						url: item.link.cached_url,
						target: item.link.target || '_self',
					};
				}),
			};
		});
	}

	static mapLanguageUrlSlug(
		storyData: ISbStoryData,
		storyLinks: TranslatedStoryblokLink[],
		language: string,
	): ISbStoryData {
		const translatedStoryData = JSON.stringify(storyData, (key, value) => {
			if (key.toLowerCase().endsWith('link') && value?.cached_url) {
				return StoryblokHelper.getTranslatedUrl(
					value,
					storyLinks,
					language,
				);
			}
			return value;
		});
		return JSON.parse(translatedStoryData);
	}

	static getTranslatedUrl(
		link: StoryblokLink,
		storyLinks: TranslatedStoryblokLink[],
		language: string,
	): StoryblokLink {
		const url = link.cached_url;
		// Preserve external links
		if (url.startsWith('http')) {
			return link;
		}
		// Make sure if we have the word home in the route to remove it and default to the slash
		if (url.endsWith('home')) {
			return {
				...link,
				cached_url: url.replace(/\/?home$/, ''),
			};
		}
		// If default language we dont have to do anything
		if (language === fallbackLanguage) return link;

		const translatedLinks = storyLinks.find((storyLink) => {
			return storyLink.uuid === link.id;
		});

		if (!translatedLinks) return link;

		const translatedUrl = translatedLinks.alternates.find(
			(alternate) => alternate.lang === language,
		);

		const translatedLink = {
			...link,
			cached_url: translatedUrl
				? '/' + language + '/' + translatedUrl.path.replace(/\/$/, '')
				: link.cached_url,
		};

		return translatedLink;
	}

	static getUrlFromStoryblokLink(link: Link): string | undefined {
		if (link && (link.id || link.url)) {
			return link.cached_url;
		}
		return undefined;
	}

	static mapBynderToPhotoInterface(
		bynder: BynderImage,
		alt: string,
	): PhotoInterface {
		return {
			src: bynder.files?.transformBaseUrl?.url || '',
			alt,
		};
	}

	static buildFormlyData(
		inputs:
			| FormInputInterface[]
			| FormHeaderInterface[]
			| FormSelectInterface[]
			| FormCheckboxInterface[],
	): FormlyFieldConfig[] {
		return inputs.map(
			(
				input: Partial<
					FormInputInterface &
						FormHeaderInterface &
						FormSelectInterface &
						FormCheckboxInterface
				>,
				index: number,
			) => {
				const tooltipObject = input.tooltipContent
					? {
							props: {
								tooltip: {
									alignment: 'top-start',
									content: input.tooltipContent,
									ariaLabel: input.tooltipLabel,
								},
							},
							wrappers: ['tooltip', 'label'],
						}
					: null;

				const inputType =
					input.type === 'tel' && input.optional
						? 'text'
						: input.type;

				const config = {
					id: input.id,
					key: input.id,
					props: {
						forceInitialValue: true,
						label: input.label,
						labelExtras: {
							contentWidth: '',
							helperText: input.helperText,
							requiredHelperText: input.optional
								? 'global.optional'
								: '',
							alignment: 'top-aligned-inline',
							width: '',
							noPadding: false,
							withMarginTop: index === 0,
						},
						optional: input.optional,
						required: !input.optional,
						translate: false,
						...tooltipObject?.props,
					},
					type: inputType,
					wrappers: tooltipObject?.wrappers || ['label'],
				};

				switch (input.component) {
					case 'formDivider': {
						return {
							className: 'section-label',
							type: 'divider',
						};
					}
					case 'formHeader': {
						return {
							props: { label: input.header },
							type: 'heading',
						};
					}
					case 'formSelect': {
						const optionsObject = this.parseStoryblokSelectOptions(
							input as FormSelectInterface,
						);

						return {
							...config,
							props: {
								...config.props,
								...optionsObject,
							},
						};
					}
					case 'formCheckbox': {
						return {
							...config,
							props: {
								...config.props,
								checkboxLabel: input.label,
								label: '',
								labelExtras: {
									...config.props.labelExtras,
									requiredHelperText: '',
								},
								showAsToggle: input.toggle,
							},
							type: 'checkbox',
						};
					}
					default: {
						const icon =
							input.icon && input.iconPosition
								? {
										icon: input.icon,
										iconPosition: input.iconPosition,
									}
								: null;

						const maskedOptions = input.mask
							? {
									maskOptions: {
										mask: input.mask,
										prefix: input.maskPrefix,
										showMaskedType: true,
									},
								}
							: null;
						return {
							...config,
							props: {
								...config.props,
								...icon,
								...maskedOptions,
								placeholder: input.placeholder || '',
							},
						};
					}
				}
			},
		);
	}

	static parsedFormData(
		data: { [key: string]: string | CheckedOption[] },
		blocks: Partial<(FormInputInterface & FormSelectInterface)[]>,
	): { id: string; label: string; value: string | CheckedOption[] }[] {
		return Object.keys(data).map((key) => {
			const block = (
				blocks as Partial<FormInputInterface & FormSelectInterface>[]
			).find(
				(block: Partial<FormInputInterface & FormSelectInterface>) =>
					block.id === key,
			) as FormInputInterface & FormSelectInterface;
			let value = data[key] || '-';

			if (Array.isArray(value)) {
				value = value
					.filter((option) => option.checked)
					.map((option) => option.inputId)
					.join(', ');
			}

			if (block.component === 'formSelect') {
				if (typeof data[key] === 'string') {
					value = block.options.find(
						(option) => option.value === data[key],
					)?.label as string | CheckedOption[];
				} else if (data[key]?.length) {
					const keys = data[key] as unknown as CheckedOption[];
					const optionValues = keys
						.map((val) => {
							if (val.checked) {
								return val.inputId;
							}
							return null;
						})
						.filter((value) => value);

					value = optionValues
						.map(
							(optionValue) =>
								block.options.find(
									(option) => option.value === optionValue,
								)?.label,
						)
						.toString();
				}
			}

			if (typeof value === 'boolean') {
				value = (value as boolean).toString();
			}

			return {
				id: key,
				label: block.label,
				value,
			};
		});
	}

	private static parseStoryblokSelectOptions(input: FormSelectInterface) {
		switch (input.type) {
			case 'checkboxGroup': {
				const options = input.options.map((option) => {
					return {
						label: option.label,
						inputId: option.value,
					};
				});
				return { checkboxOptions: options };
			}
			case 'radioGroup': {
				const options = input.options.map((option) => {
					return {
						label: option.label,
						inputId: option.value,
					};
				});
				return { radioOptions: options };
			}
			case 'segmentedControl': {
				const options = input.options.map((option) => {
					return {
						name: option.label,
						value: option.value,
					};
				});
				return { segmentedOptions: options };
			}
			case 'select':
			default: {
				const options = input.options.map((option) => {
					return {
						label: option.label,
						value: option.value,
					};
				});
				return { selectOptions: options };
			}
		}
	}

	static checkNegativeHeader(content: { component: string }) {
		let useNegative = false;

		if (content) {
			useNegative =
				content.component === 'carousel' ||
				content.component === 'carouselWithAvailabilityWidget' ||
				content.component === 'carouselWithWidget';
		}

		negativeHeader$.next(useNegative);
	}
}
