<footer class="footer" data-test-id="footer">
	<div
		[ngClass]="
			isMinimal
				? 'flex-col items-center gap-6 py-4 md:flex-row md:justify-between'
				: 'flex-col'
		"
		class="mx-auto flex w-full max-w-screen-2xl px-3 md:relative md:px-8 2xl:px-10"
	>
		@if (!isMinimal) {
			<div class="self-center md:self-start">
				<button
					(click)="scrollToTop()"
					class="footer-button visited:text-white"
					role="button"
					type="button"
				>
					{{ 'global.to-top' | translate }}
				</button>
			</div>
		}
		<div
			[ngClass]="isMinimal ? 'flex-row' : 'flex-col py-6 md:pt-0'"
			class="flex items-center font-logo uppercase"
		>
			<img
				[ngClass]="isMinimal ? 'h-8 w-7' : 'mb-4 h-10 w-8'"
				alt="Van der Valk Logo"
				src="assets/icons/logo-valk-white.svg"
			/>
			<div
				[ngClass]="[
					isMinimal ? 'ml-1.5 border-l pl-1.5' : 'items-center',
					isMinimal && !title ? 'h-8 justify-center' : '',
				]"
				class="flex w-full flex-col"
			>
				<span
					[ngClass]="[
						isMinimal ? 'mb-0.5 text-base' : 'mb-2 text-xl',
						isMinimal && !title ? '!mb-0' : '',
					]"
					class="font-normal leading-none"
				>
					{{ 'global.brand-name' | translate }}
				</span>
				@if (title) {
					<span
						[ngClass]="
							isMinimal
								? 'mb-0 text-[22px] font-semibold'
								: 'mb-0 text-3xl font-bold'
						"
						class="font-bold leading-none"
					>
						{{ title }}
					</span>
				}
			</div>
		</div>
		@if (!isMinimal) {
			<hr class="divider footer-divider my-2 opacity-25" />
			<div class="my-3 flex flex-wrap justify-center gap-y-3">
				@for (usp of usps; track usp) {
					<div class="flex w-1/2 flex-col items-center md:w-44">
						<vp-link
							[link]="{ url: usp.url, title: usp.text }"
							className="flex flex-col items-center text-center text-white no-underline visited:text-white hover:text-white md:w-44"
						>
							<div
								class="footer-bg-brand mb-1 size-10 rounded-full p-2"
							>
								<svg-icon
									[src]="usp.icon"
									class="block size-6 text-white"
								/>
							</div>
							{{
								usp.text || 'global.best-price-guarantee'
									| translate
							}}
						</vp-link>
					</div>
				}
			</div>
			<hr class="divider footer-divider my-2 mb-10 opacity-25" />
			<div
				class="mb-10 flex flex-col justify-center gap-x-6 gap-y-10 md:flex-row lg:mx-1/6"
			>
				@for (
					menuItems of menu.items;
					track menuItems;
					let last = $last
				) {
					@if (!last || !information) {
						<div
							[ngClass]="{
								'md:w-1/4 lg:w-1/6': menu.items.length !== 1,
								'flex flex-col justify-center':
									menu.items.length === 1,
							}"
						>
							<span
								class="mb-4 block text-center text-2xl font-semibold md:text-left"
							>
								{{ menuItems.title }}
							</span>
							<ul class="flex flex-col gap-y-4">
								@for (
									menuItem of menuItems.items;
									track menuItem
								) {
									@if (menuItem.title) {
										<li class="text-center md:text-left">
											<vp-link
												[link]="menuItem"
												className="text-lg font-normal !text-white no-underline"
											>
												{{ menuItem.title }}
											</vp-link>
										</li>
									}
								}
							</ul>
						</div>
					}
				}
				@if (information) {
					<div class="flex flex-col">
						<span
							class="mb-4 text-center text-2xl font-semibold md:text-left"
						>
							{{ 'components.footer.contact-title' | translate }}
						</span>
						@if (information.contact) {
							<div class="flex flex-col gap-y-6">
								@if (information.contact.phone) {
									<div
										class="flex flex-col items-center gap-y-1 md:items-start"
									>
										<span class="block text-light-primary">
											{{
												'components.footer.phone-information'
													| translate
											}}
										</span>
										<div class="flex items-center">
											<svg-icon
												class="footer-text-brand mr-2 size-4"
												src="assets/icons/icon-phone.svg"
											/>
											<a
												[href]="
													'tel:' +
													information.contact.phone
												"
												class="!footer-text-brand text-lg no-underline"
											>
												{{ information.contact.phone }}
											</a>
										</div>
									</div>
								}
								@if (information.contact.whatsapp) {
									<div
										class="flex flex-col items-center gap-y-1 md:items-start"
									>
										<span class="block text-light-primary">
											{{
												'components.footer.whatsapp-information'
													| translate
											}}
										</span>
										<div class="flex items-center">
											<svg-icon
												class="footer-text-brand mr-2 size-4"
												src="assets/icons/icon-whatsapp.svg"
											/>
											<a
												[href]="
													information.contact.whatsapp
														| whatsapp
												"
												class="!footer-text-brand text-lg no-underline"
											>
												{{
													information.contact.whatsapp
												}}
											</a>
										</div>
									</div>
								}
								@if (information.contact.email) {
									<div
										class="flex flex-col items-center gap-y-1 md:items-start"
									>
										<span class="block text-light-primary">
											{{
												'components.footer.email-information'
													| translate
											}}
										</span>
										<div class="flex items-center">
											<svg-icon
												class="footer-text-brand mr-2 size-4"
												src="assets/icons/icon-envelope.svg"
											/>
											<a
												[href]="
													'mailto:' +
													information.contact.email
												"
												class="footer-text-brand text-lg no-underline"
											>
												{{ information.contact.email }}
											</a>
										</div>
									</div>
								}
							</div>
						}
						@if (information.address) {
							<div
								class="mt-10 flex flex-col items-center md:items-start"
							>
								@if (information.name) {
									<span class="mb-1 text-xl font-semibold">
										{{ information.name }}
									</span>
								}
								<span class="text-lg text-light-secondary">
									{{ information.address.street }}
									{{ information.address.streetNumber }}
									{{
										information.address.streetNumberAddition
									}}
								</span>
								<span class="text-lg text-light-secondary">
									{{ information.address.postalCode }}
								</span>
								<span class="text-lg text-light-secondary">
									{{ information.address.city }}
								</span>
								@if (information.address.gps) {
									<div class="mt-3 flex items-center">
										<svg-icon
											class="footer-text-brand mr-2 size-5"
											src="assets/icons/icon-location-marker.svg"
										/>
										<a
											[href]="
												'https://www.google.com/maps/dir/?api=1&destination=' +
												information.address.gps +
												'&travelmode=driving'
											"
											class="!footer-text-brand text-lg no-underline"
											target="_blank"
										>
											{{
												'components.footer.plan-route'
													| translate
											}}
										</a>
									</div>
								}
							</div>
						}
					</div>
				}
			</div>
		}
		<div class="flex flex-row">
			<div class="w-full">
				<div
					[ngClass]="{ 'mb-10 pt-5': !isMinimal }"
					class="flex w-full flex-wrap justify-center gap-x-4 gap-y-3 md:gap-x-10"
				>
					@if (socials?.facebook) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.facebook,
								social: 'Facebook',
								icon: 'facebook',
							}"
						/>
					}
					@if (socials?.instagram) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.instagram,
								social: 'Instagram',
								icon: 'instagram',
							}"
						/>
					}
					@if (socials?.tiktok) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.tiktok,
								social: 'Tiktok',
								icon: 'tiktok',
							}"
						/>
					}
					@if (socials?.linkedin) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.linkedin,
								social: 'LinkedIn',
								icon: 'linkedin',
							}"
						/>
					}
					@if (socials?.youtube) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.youtube,
								social: 'Youtube',
								icon: 'youtube',
							}"
						/>
					}
					@if (socials?.pinterest) {
						<ng-container
							[ngTemplateOutlet]="social"
							[ngTemplateOutletContext]="{
								link: socials!.pinterest,
								social: 'Pinterest',
								icon: 'pinterest',
							}"
						/>
					}
				</div>
			</div>
		</div>
	</div>
	<div class="footnote">
		<div
			class="mx-auto flex w-full max-w-screen-2xl flex-col px-3 md:relative md:flex-row-reverse md:justify-between md:px-8 md:py-4 2xl:px-10"
		>
			<div
				class="footer-text-brand mb-2 mt-4 self-center text-lg font-bold lowercase md:my-0"
			>
				{{ 'global.valk-key' | translate }}
			</div>
			<ul
				class="mb-6 flex flex-wrap justify-center gap-4 text-light-primary md:my-0 md:self-center"
			>
				@for (item of bottomItems; track item) {
					<li class="text-sm" data-test-class="bottom-item">
						<vp-link
							[link]="item"
							className="text-light-primary no-underline visited:text-light-primary"
						>
							{{ item.text }}
						</vp-link>
					</li>
				}
			</ul>
		</div>
	</div>
</footer>

<ng-template #social let-icon="icon" let-link="link" let-social="social">
	<a
		[attr.data-test-id]="'social-link-' + social"
		[href]="link"
		class="flex items-center gap-x-2 text-lg text-light-primary no-underline visited:text-white"
		rel="noopener noreferrer"
		target="_blank"
	>
		<svg-icon
			[src]="'assets/icons/icon-' + icon + '.svg'"
			class="footer-text-brand block size-4"
		/>
		{{ social }}
	</a>
</ng-template>
