import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	OnInit,
} from '@angular/core';

import { UspBarComponent } from '@valk-nx/components/ui-usp-bar/src/lib/usp-bar.component';
import { USPInterface } from '@valk-nx/components/ui-usp-bar/src/lib/usp-bar.interface';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { Link } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

type Usp = {
	icon: string;
	title: string;
	description: string;
	link?: Link | string;
};

@Component({
	selector: 'sb-usp-wrapper',
	templateUrl: './usp-wrapper.html',
	standalone: true,
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [StoryblokDirective, UspBarComponent],
})
export class UspWrapperComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) USP: Usp[] = [];
	usps: USPInterface[] = [];

	ngOnInit() {
		this.usps = this.USP.map((item) => ({
			...item,
			link: (item.link as Link)?.cached_url,
			target: (item.link as Link)?.target,
		}));
	}
}
