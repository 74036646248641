import plugin from 'tailwindcss/plugin';

const accordionPlugin = plugin.withOptions(function () {
	return function ({ addComponents, theme, e }) {
		addComponents({
			[`.${e('accordion')}`]: {
				background: theme('backgroundColor.white'),
				borderRadius: `var(--accordion-border-radius, ${theme(
					'borderRadius.DEFAULT',
				)})`,
				borderWidth: '1px',
				borderLeftWidth: '4px',
			},
			[`.${e('accordion-base')}`]: {
				borderColor: `var(--accordion-color-border, ${theme(
					'colors.ui.100',
				)})`,

				['&[open], &.open, &:hover']: {
					borderColor: `var(--accordion-color-border-hover, ${theme(
						'colors.ui.200',
					)})`,
				},
			},
			[`.${e('accordion-accent')}`]: {
				borderColor: `var(--accordion-accent-color-border, ${theme(
					'colors.accent.50',
				)})`,

				['&[open], &.open, &:hover']: {
					borderColor: `var(--accordion-accent-color-border-hover, ${theme(
						'colors.accent.100',
					)})`,
				},
			},
		});
	};
});

export default accordionPlugin;
