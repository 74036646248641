import { CommonModule } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AlertComponent } from '@valk-nx/components/ui-alert/src/lib/alert.component';
import { RecaptchaNotificationComponent } from '@valk-nx/components/ui-recaptcha-notification/src/lib/recaptcha-notification';
import { Language } from '@valk-nx/core/lib/core';
import { CheckedOption } from '@valk-nx/core/lib/interfaces/form.interface';
import { SharedFormlyModule } from '@valk-nx/formly/lib/formly.module';
import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';
import { FormService } from '@valk-nx/services/form/src/lib/form.service';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import {
	FormInputInterface,
	FormSelectInterface,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-form',
	templateUrl: './form.html',
	standalone: true,
	imports: [
		AlertComponent,
		CommonModule,
		TranslateModule,
		RecaptchaNotificationComponent,
		StoryblokDirective,
		SharedFormlyModule,
	],
})
export class FormComponent extends StoryblokRootDirective implements OnInit {
	@Input({ required: true }) subject!: string;
	@Input({ required: true }) blocks: (FormInputInterface &
		FormSelectInterface)[] = [];
	@Input({ required: true }) buttonText!: string;
	@Input({ required: true }) successTitle!: string;
	@Input({ required: true }) successContent!: string;

	@ViewChild('formRef') formRef!: NgForm;
	formFields: FormlyFieldConfig[] = [];
	formGroup = new FormGroup({});
	options: FormlyFormOptions = {};
	model = {};

	parsedFormData: {
		id: string;
		label: string;
		value: string | CheckedOption[];
	}[] = [];
	requestSubmitted = false;
	requestSubmitting = false;
	language: Language;

	constructor(
		private readonly cd: ChangeDetectorRef,
		private readonly formService: FormService,
		private readonly translate: TranslateService,
	) {
		super();
		this.language = (this.translate.currentLang ||
			this.translate.defaultLang) as Language;
	}

	ngOnInit() {
		this.formFields = StoryblokHelper.buildFormlyData(this.blocks);
	}

	async onSubmit(): Promise<void> {
		if (this.formGroup.valid) {
			this.requestSubmitting = true;

			this.parsedFormData = StoryblokHelper.parsedFormData(
				this.formGroup.value,
				this.blocks,
			);

			return this.formService
				.submitCustomForm(this.subject, this.parsedFormData)
				.then((response) => {
					this.requestSubmitting = false;
					this.requestSubmitted = response.success;
					this.cd.detectChanges();
				})
				.catch(() => {
					this.requestSubmitting = false;
					this.cd.detectChanges();
				});
		} else {
			this.scrollToError();
		}
	}

	resetForm(): void {
		this.requestSubmitted = false;
		this.requestSubmitting = false;

		this.formGroup.reset();
		this.formRef.resetForm();
	}

	scrollToError() {
		setTimeout(() => {
			GeneralHelper.scrollToElementWithClass(
				'form-field-error',
				undefined,
				'input',
			);
		}, 100);
	}
}
