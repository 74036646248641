import { CommonModule } from '@angular/common';
import { Component, effect, Input, input } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { ColumnAlignment } from '@valk-nx/storyblok-pipes/src/lib/pipes/column-alignment/column-alignment.pipe';
import {
	ColumnComponentAlignments,
	ColumnComponentTypes,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-columns',
	templateUrl: './columns.html',
	standalone: true,
	imports: [StoryblokDirective, CommonModule, DynamicModule, ColumnAlignment],
})
export class ColumnsComponent extends StoryblokRootDirective {
	@Input() title!: ISbStoryData[];
	@Input() colOne!: ISbStoryData;
	@Input() colTwo!: ISbStoryData;
	@Input() colThree!: ISbStoryData;
	@Input() fullWidth = false;
	@Input() growColumns = false;
	@Input() reverse = false;
	@Input() declare component: ColumnComponentTypes;

	alignment = input<ColumnComponentAlignments>('left');
	components!: typeof Components;

	columnAlignment: 'items-start' | 'items-center' | 'items-end' =
		'items-start';

	constructor() {
		super();
		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);

		effect(() => {
			switch (this.alignment()) {
				case 'left':
					this.columnAlignment = 'items-start';
					break;
				case 'center':
				default:
					this.columnAlignment = 'items-center';
					break;
			}
		});
	}
}
