@if (roomData$ | async; as roomData) {
	<div data-test-id="room-detail">
		<div class="flex flex-col">
			<vp-carousel [items]="images" data-test-id="room-detail-carousel" />
			@if (roomData.isBookable) {
				<div class="z-10 -mt-14 px-3 md:px-8 2xl:px-10">
					<div class="mx-auto w-full max-w-screen-2xl">
						<div
							class="mx-auto flex w-full flex-col lg:w-10/12 2xl:w-8/12"
						>
							<vp-availability-widget
								[bookingtoolUrl]="
									this.bookingtool + this.hotelSlug
								"
								[hotelGUID]="roomData.hotel.GUID"
								[occupancyRestrictions]="
									roomData.occupancyRestrictions
								"
								[roomGUID]="roomData.GUID"
							/>
						</div>
					</div>
				</div>
			}
		</div>
		<section class="section-spacing bg-white">
			<div class="section-gap mx-auto max-w-screen-2xl">
				<div
					class="flex w-full flex-col gap-content-section md:mx-auto md:flex-row lg:w-10/12"
				>
					<div
						class="flex w-full flex-col gap-content-section lg:w-1/2 xl:w-2/3"
					>
						<div>
							<header>
								<h1
									class="heading-group heading-group-lg"
									data-test-id="room-detail-title"
								>
									{{ roomData.title }}
								</h1>
								<p
									class="heading-group-overline"
									role="doc-subtitle"
								>
									{{ roomData.category }}
								</p>
							</header>
							<vp-room-facilities
								[babyBeds]="0"
								[facilities]="roomData.facilities"
								[maxNumberOfGuests]="0"
								[roomIndex]="0"
								[roomSurface]="roomData.surfaceArea"
								[showMoreInformationAccordion]="false"
								data-test-id="room-detail-facilities"
							/>
						</div>
						<vp-read-more>
							<markdown
								[data]="roomData.description"
								data-test-id="room-detail-description"
							/>
						</vp-read-more>
					</div>
					<div class="w-full lg:w-1/2 xl:w-1/3">
						@if (roomData.isBookable) {
							<vp-persuade-card
								[ctaText]="'global.book-directly' | translate"
								[ctaUrl]="
									this.bookingtool +
									this.hotelSlug +
									'?roomGUID=' +
									roomData.GUID
								"
								[ctaVariant]="'btn-outline'"
								[currency]="roomData.price.currency"
								[price]="roomData.price.amount"
								[reversedCtaColumn]="true"
								[title]="
									'global.book-accommodation-type'
										| translate
											: {
													type:
														([
															roomData.accommodationType,
														] | accommodationType),
											  }
								"
								[useVerticalCard]="true"
								data-test-id="cta-card"
							>
								<div #content class="list-checkmarks">
									<ul>
										<li data-test-id="cta-card-checkin">
											{{
												'global.checkin-time'
													| translate
														: {
																time: roomData.checkInTime,
														  }
											}}
										</li>
										<li data-test-id="cta-card-checkout">
											{{
												'global.checkout-time'
													| translate
														: {
																time: roomData.checkOutTime,
														  }
											}}
										</li>
										<li>
											{{
												'global.best-price-guarantee'
													| translate
											}}
										</li>
									</ul>
								</div>
							</vp-persuade-card>
						}
					</div>
				</div>
			</div>
		</section>
		<section class="section-spacing bg-white">
			<div class="section-gap mx-auto flex max-w-screen-2xl flex-col">
				<div
					class="flex w-full flex-col gap-content-section md:mx-auto md:flex-row lg:w-10/12"
				>
					<div
						class="list-checkmarks list-grid flex w-full flex-col gap-4 md:!w-2/3 md:gap-8"
					>
						<h2 class="running-head mb-0">
							{{ 'global.facilities' | translate }}
						</h2>
						<ng-container
							[ngTemplateOutlet]="list"
							[ngTemplateOutletContext]="{
								title: 'global.around-accommodation-type',
								listItems: roomFacilities || [],
								grid: true,
								accommodationType: roomData.accommodationType,
							}"
						/>
						<ng-container
							[ngTemplateOutlet]="list"
							[ngTemplateOutletContext]="{
								title: 'global.around-hotel',
								listItems: roomData.hotel?.facilities || [],
								grid: true,
								accommodationType: roomData.accommodationType,
							}"
						/>
					</div>
					<div class="w-full md:w-1/3"></div>
				</div>
				<div
					class="flex w-full flex-col gap-4 md:mx-auto md:flex-row md:gap-6 lg:w-10/12 2xl:gap-8"
				>
					<div
						class="list-bullets list-grid flex w-full flex-col gap-4 md:!w-2/3 md:gap-8"
					>
						<h2 class="running-head mb-0">
							{{ 'global.good-to-know' | translate }}
						</h2>
						<ng-container
							[ngTemplateOutlet]="list"
							[ngTemplateOutletContext]="{
								listItems: roomData.hotel?.goodToKnows || [],
								grid: false,
								accommodationType: roomData.accommodationType,
							}"
						/>
					</div>
					<div class="w-full md:w-1/3"></div>
				</div>
			</div>
		</section>
	</div>
}

<ng-template
	#list
	let-accommodationType="accommodationType"
	let-grid="grid"
	let-listItems="listItems"
	let-title="title"
>
	<div class="flex flex-col gap-4 md:gap-8">
		@if (title) {
			<h3 class="h4 mb-0">
				{{
					title
						| translate
							: {
									type:
										([accommodationType]
										| accommodationType),
							  }
				}}
			</h3>
		}
		<ul
			[ngClass]="{ 'sm:grid-cols-2 lg:grid-cols-3': grid }"
			class="grid grid-cols-1 gap-4"
		>
			@for (item of listItems; track item) {
				<li>{{ item }}</li>
			}
		</ul>
	</div>
</ng-template>
