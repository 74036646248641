<vp-storytelling
	[editableInStoryblok]="_editable"
	[image]="vpImage"
	[position]="alignment"
	[useBackground]="useBackground"
>
	<header>
		<h2 class="heading-group heading-group-lg mb-4">
			{{ title }}
		</h2>
		<p class="heading-group-overline" role="doc-subtitle">
			{{ overline }}
		</p>
	</header>

	<div class="-mt-1.5 flex flex-col gap-1.5">
		@for (blok of content; track blok) {
			@if (components) {
				<ndc-dynamic
					[ndcDynamicComponent]="components[blok['component']]"
					[ndcDynamicInputs]="blok"
				/>
			}
		}
	</div>
</vp-storytelling>
