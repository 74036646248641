<div
	[ngClass]="{ 'sm:gap-8': !isVertical }"
	class="flex flex-wrap justify-center"
	data-test-id="usp-bar"
>
	@for (usp of usps; track usp) {
		<div
			[ngClass]="{
				'sm:w-[calc(50%-16px)] md:w-[calc(33%-20px)] lg:w-[calc(25%-24px)]':
					!isVertical,
			}"
			class="w-full p-2"
			data-test-class="usp"
		>
			@if (!usp.link) {
				<div
					[ngClass]="{
						'md:flex-col md:items-center md:gap-1 md:text-center':
							isStacked,
					}"
					class="flex gap-4"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp: usp }"
					/>
				</div>
			}
			@if (usp.link) {
				<a
					[href]="usp.link"
					[ngClass]="{
						'md:flex-col md:items-center md:gap-1 md:text-center':
							isStacked,
					}"
					[target]="usp.target || '_self'"
					class="flex gap-4 no-underline outline-black"
					rel="noopener noreferrer"
				>
					<ng-container
						[ngTemplateOutlet]="uspTemplate"
						[ngTemplateOutletContext]="{ usp: usp }"
					/>
				</a>
			}
		</div>
	}
</div>

<ng-template #uspTemplate let-usp="usp">
	<svg-icon
		[class]="iconVariant + ' ' + iconType"
		[ngClass]="{
			'icon-container-elevated': isElevatedIcon,
			'icon-container-lg-full': isStacked,
			'md:icon-container-lg': !isStacked,
		}"
		[src]="usp.icon"
		class="icon-container shrink-0"
	/>
	<div class="my-auto flex flex-grow flex-col">
		<span
			[ngClass]="{
				'text-dark-tertiary': iconVariant === 'default',
				'text-dark-primary': iconVariant !== 'default',
				'md:items-center md:justify-center': isStacked,
			}"
			class="flex flex-row font-sans-serif text-sm font-bold md:text-base"
			data-test-id="usp-bar-title"
		>
			<span [ngClass]="{ 'md:flex-grow-0': isStacked }" class="flex-grow">
				{{ usp.title | translate }}</span
			>
			@if (usp.link) {
				<svg-icon
					class="icon-container icon-container-sm ml-0 mt-0.5 flex-none md:mt-1"
					src="assets/icons/icon-chevron-right.svg"
				/>
			}
		</span>
		<span
			[ngClass]="
				iconVariant === 'default'
					? 'text-dark-tertiary'
					: 'text-dark-primary'
			"
			class="font-sans-serif text-sm md:text-base"
			data-test-id="usp-bar-description"
			>{{ usp.description | translate }}</span
		>
	</div>
</ng-template>
