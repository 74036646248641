<div [ngClass]="className" class="relative">
	@if (label.length > 0 && inputId.length > 0) {
		<label [for]="inputId" class="sr-only"> {{ label }} </label>
	}
	@if (useAsFormControl) {
		<ng-select
			[class]="variant"
			[clearable]="false"
			[dropdownPosition]="dropdownPosition"
			[formControl]="formControl"
			[labelForId]="inputId"
			[ngClass]="{
				valid: isValid && !hasError && isRequired,
				error: hasError,
				centered: centered,
				searchable: searchable,
				'dropdown-white': isWhite,
			}"
			[placeholder]="(translatedPlaceholder$ | async) || '' | translate"
			[searchable]="searchable"
			(change)="update()"
			class="dropdown"
			data-test-id="select-dropdown"
		>
			<ng-template ng-label-tmp>
				<span
					class="ng-value-label"
					data-test-id="select-dropdown-selected-label"
				>
					{{ selectedDropdownPlaceholder$ | async }}
				</span>
			</ng-template>
			@for (option of selectOptions$ | async; track option.value) {
				<ng-option [value]="option.value">
					@if (option?.data?.icon) {
						<img
							[src]="option.data?.icon"
							alt=""
							class="icon mr-2 inline-block size-5"
						/>
					}
					{{ option.selectLabel || option.label }}
				</ng-option>
			}
		</ng-select>
	} @else {
		<ng-select
			[class]="variant"
			[clearable]="false"
			[dropdownPosition]="dropdownPosition"
			[labelForId]="inputId"
			[ngClass]="{
				valid: isValid && !hasError && isRequired,
				error: hasError,
				centered: centered,
				searchable: searchable,
				'dropdown-white': isWhite,
			}"
			[placeholder]="(translatedPlaceholder$ | async) || ''"
			[searchable]="searchable"
			[(ngModel)]="value"
			(change)="update()"
			class="dropdown"
			data-test-id="select-dropdown"
		>
			<ng-template ng-label-tmp>
				<span
					class="ng-value-label"
					data-test-id="select-dropdown-selected-label"
				>
					{{ selectedDropdownPlaceholder$ | async }}
				</span>
			</ng-template>
			@for (option of selectOptions$ | async; track option.value) {
				<ng-option [value]="option.value">
					@if (option?.data?.icon) {
						<img
							[src]="option.data?.icon"
							alt=""
							class="icon mr-2 inline-block size-5"
						/>
					}
					{{ option.selectLabel || option.label }}
				</ng-option>
			}
		</ng-select>
	}
	@if (isValid || hasError || (formControl.value && (isValid || hasError))) {
		<div
			class="absolute top-3 z-[2000] -ml-2.5 size-5 rounded-full border-2 border-white bg-white"
		>
			@if (isValid) {
				<svg-icon
					class="text-success-100"
					data-test-id="valid-icon"
					src="assets/icons/icon-checkmark-circle-solid.svg"
				/>
			}
			@if (hasError) {
				<svg-icon
					class="text-error-100"
					data-test-id="error-icon"
					src="assets/icons/icon-exclamation-mark-circle-solid.svg"
				/>
			}
		</div>
	}
</div>
