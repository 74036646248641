const textColors = {
	light: {
		quaternary: '#FFFFFF4D',
		tertiary: '#FFFFFF8C',
		secondary: '#FFFFFFA6',
		primary: '#FFFFFFBF',
	},
	dark: {
		quaternary: '#0000004D',
		tertiary: '#0000008C',
		secondary: '#000000A6',
		primary: '#000000BF',
	},
	grey: {
		100: '#737373',
		200: '#595959',
		300: '#404040',
	},
};

const colors = {
	transparent: 'transparent',
	current: 'currentColor',
	white: '#FFFFFF',
	black: '#000000',
	accent: {
		10: '#F8F5EF',
		20: '#F1EADF',
		50: '#DCCBAF',
		100: '#BA975F',
		200: '#93703E',
	},
	success: {
		10: '#E9FBEA',
		100: '#27D32E',
		300: '#018401',
	},
	error: {
		20: '#FFD6D6',
		50: '#FF9999',
		100: '#FF3333',
		200: '#CC2929',
	},
	warning: {
		20: '#FFF2DA',
		50: '#FFDFA2',
		100: '#FFC045',
		200: '#CC9A37',
	},
	highlight: {
		20: '#FEFFDF',
		50: '#FDFFAF',
		100: '#FCFF5F',
		200: '#CACC4C',
	},
	focus: {
		100: '#67DBFF',
	},
	select: {
		20: '#CCEBF5',
		50: '#80CCE5',
		100: '#0099CC',
		200: '#006D98',
		300: '#3C7B90',
	},
	ui: {
		10: '#F8F8F8',
		30: '#F3F3F3',
		50: '#EBEBEB',
		100: '#D8D8D8',
		200: '#C4C4C4',
		300: '#B1B1B1',
		400: '#9D9D9D',
	},
	cta: {
		100: '#FF6600',
		200: '#D66215',
	},
};
module.exports = {
	textColors,
	colors,
};
